import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MyAccount from 'src/layouts/MyAccount';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/Loader';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import SettingsGuard from 'src/components/Guards/SetingsGuard';
import SettingsLayout from 'src/views/Test';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen open={true}/>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signUp',
    component: lazy(() => import('src/views/auth/LoginView/SignUp'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },
  {
    path: '/app/admin/profile1',
    guard: AuthGuard,
    layout: MyAccount,
    routes: [
     
      // {
      //   exact: true,
      //   path: '/app/admin/profile1/personaldetails',
      //   component: lazy(() => import('src/views/profile2profile'))
      // },
   
      {
        exact: true,
        path: '/app/admin/profile1/Access',
        component: lazy(() => import('src/views/Access'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access1',
        component: lazy(() => import('src/views/Access2'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access2',
        component: lazy(() => import('src/views/Access3'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access3',
        component: lazy(() => import('src/views/Access4'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access4',
        component: lazy(() => import('src/views/Access5'))
      },
      {
        exact: true,
        path: '/app/admin/profile1/Access5',
        component: lazy(() => import('src/views/Access6'))
      },
    
    
      

      
      
      
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/admin/home',
        component: lazy(() => import('src/views/HomeView'))
      },
      {
        exact: true,
        path: '/app/records/:page_id/:schedule_id',
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/mla_sched/:page_id/:schedule_id',
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/inst_sched/:page_id/:schedule_id',
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        // guard: SettingsGuard,
        path: '/app/Hostel/check_in_out_device',
        component: lazy(() => import('src/views/CheckInOutDevice'))
      },
      
     
    
     
      // {
      //   exact: true,
      //   path: '/app/payroll/profile/Accomplishments',
      //   component: lazy(() => import('src/views/AccomplishmentsDetailsEdit'))
      // },
    
      // {
      //   exact: true,
      //   path: '/app/payroll/attendance',

      //   component: lazy(() => import('src/views/Attendance/ContractList'))
      // },
      // {
      //   exact: true,
      //   path: '/app/payroll/attendance/:id',
      //   component: lazy(() => import('src/views/Attendance/List'))
      // },
   
    
   
      {
        exact: true,
        path: '/app/payroll/more',
        component: lazy(() => import('src/views/More'))
      },
    
      {
        exact: true,
        path: '/app/payroll/Organization',
        component: lazy(() => import('src/views/Organization'))
      },
     
     
      //   exact: true,
      //   path: '/app/payroll/leaveApplications',
      //   component: lazy(() => import('src/views/LeaveApplications'))
      // },

    
      
    
     
     
    
     
      {
        exact: true,
        path: '/app/payroll/reports',
        component: lazy(() => import('src/views/Reports'))
      },
    
    
    

      // {
      //   exact: true,
      //   path: '/app/admin/Faculty',
      //   component: lazy(() => import('src/views/Faculty'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/student/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/book/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/check/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/orders/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/Id_card/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      {
        exact: true,
        path: '/app/admin/grievance/admission/:admission_status',
        component: lazy(() => import('src/views/StudentsList'))
      },
      {
        exact: true,
        path: '/app/fee/transactions/create_order',
        component: lazy(() => import('src/views/InvoiceNew'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/device/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      {
        exact: true,
        path: '/app/admin/student/:page_id/:type',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/student/:page_id/:type/:id/:layout_id',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/students/admission/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      // {
      //   exact: true,
      //   path: '/app/payroll/Employee/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/payroll/leaveApplications/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/leaveApplications/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/Faculty_Attendance/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/Employees/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
        
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/movement_register/:page_id',
      //   component: lazy(() => import('src/views/Employee'))
      // },
      {
        exact: true,
        path: '/app/admin/students/tracker/admission/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      {
        exact: true,
      

        path: '/app/admin/settings/user/user_management',
        component: lazy(() => import('src/views/UserManagement'))
      },
      {
        exact: true,
      

        path: '/app/admin/people',
        component: lazy(() => import('src/views/UserManagementPeople'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/admin/add/:type/:schedule_id',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/edit/:type/:id/:layout_id',
        component: lazy(() => import('src/views/AddEmployeeDetails'))
      },
     
    
    
     
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/programs',
        component: lazy(() => import('src/views/InstSetup/Programs'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/quota',
        component: lazy(() => import('src/views/InstSetup/Quota'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream',
        component: lazy(() => import('src/views/InstSetup/Stream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/addStream',
        component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id',
        component: lazy(() => import('src/views/InstSetup/Stream/Details'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch',
        component: lazy(() => import('src/views/InstSetup/Batch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/addBatch',
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/academicyear',
        component: lazy(() => import('src/views/InstSetup/AcademicYear'))
      },
     
      
      // {
      //   layout : SettingsLayout,
      //   exact: true,
      //   path: '/app/setting/usermanagement/adminsNew',
      //   component: lazy(() => import('src/views/UsersNew'))
      // },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/templates/settings/EmailTemplate',
        component: lazy(() => import('src/views/InstSetup/EmailTemplate'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Company',
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Usermanagement/Users/UsersDetails',
        component: lazy(() => import('src/views/InstSetup/Users'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/InstSetup/Integrations/Integration'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Personal',
        component: lazy(() => import('src/views/PersonalSetup'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/Integration'))
      },
      // {
      //   exact: true,
      //   guard: SettingsGuard,
      //   layout : SettingsLayout,

      //   path: '/app/setting/usermanagement/user_management',
      //   component: lazy(() => import('src/views/UserManagement'))
      // },
    ]
  },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
